/*
 * @Date: 2024-09-03 10:35:01
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-09-22 00:05:15
 * @FilePath: \zyt-mobile-frontend\src\config\index.js
 */
export default {
  // apiUrl: 'http://localhost:3000/api'
  appid: "2002207363",
  // 浙影通h5地址
  h5Url: "http://zyt.com:3344",
  isDev: process.env.NODE_ENV === 'development',
  // 观看放映人数范围取值
  audienceRange: [
    { text: '5-10人', value: 5 },
    { text: '10-20人', value: 10 },
    { text: '20-30人', value: 20 },
    { text: '30-40人', value: 30 },
    { text: '40-50人', value: 40 },
    { text: '50-60人', value: 50 },
    { text: '60-70人', value: 60 },
    { text: '70-80人', value: 70 },
    { text: '80-90人', value: 80 },
    { text: '90-100人', value: 90 },
    { text: '100人以上', value: 100 },
  ]
}
